import type { FC } from 'react';
import type { LinksFunction, ThrownResponse } from 'remix';
import { Link } from 'remix';
import Card from './Card';
import { Flex, Heading, Button as ChakraButton, Text } from '@chakra-ui/react';
import ErrorSVG, { links as errorSVGLinks } from '../error-anim/error-anim';

export interface Props {
    caught: ThrownResponse<number, unknown>;
}

export const links: LinksFunction = () => {
    return [...errorSVGLinks()];
};

const CatchBoundaryCard: FC<Props> = (props) => {
    const { caught } = props;
    return (
        <Card justifyContent="space-evenly">
            <Heading>{caught.status === 404 ? <ErrorSVG height="100px" /> : caught.status}</Heading>
            <Heading p={5} color={'secondary'}>
                Page not found
            </Heading>
            <Text p={5} color={'secondary'}>
                It looks like the page you’re looking for has gone missing.
            </Text>
            <Flex p={5} justifyContent={'center'}>
                <Link to="/requests">
                    <ChakraButton display="flex">Go Back</ChakraButton>
                </Link>
            </Flex>
        </Card>
    );
};

export default CatchBoundaryCard;
