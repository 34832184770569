import React from 'react';
import { withEmotionCache } from '@emotion/react';
import { ChakraProvider } from '@chakra-ui/react';
import {
    Links,
    LiveReload,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useCatch,
    useLoaderData
} from 'remix';
import type { MetaFunction, LinksFunction } from 'remix'; // Depends on the runtime you choose
import type { EmotionCache } from '@emotion/react';
import Footer, { links as footerLinks } from '~/components/footer/Footer';
import { NavBar, links as navLinks } from '@components/nav/Nav';
import Button from './components/button/Button';
import { useSubmit } from 'remix';
import type { MouseEvent } from 'react';
import { useEmotionCache } from './hooks/useEmotionCache';
import CatchBoundaryCard, {
    links as catchBoundaryCardLinks
} from './components/card/CatchBoundaryCard';
import ErrorBoundaryCard from './components/card/ErrorBoundaryCard';
import styles from 'app/styles/dest/main.css';
import DOMPurify from 'isomorphic-dompurify';
import lightTheme from './theme/lightTheme';
import darkTheme from './theme/darkTheme';
import type { HeadersFunction, LoaderFunction } from 'remix';
import { getColorScheme } from './cookie';
import { logoChildren, NavChildren } from './routes/requests';

interface DocumentProps {
    children: React.ReactNode;
}

export const links: LinksFunction = () => {
    return [
        { rel: 'stylesheet', href: styles },
        { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
        { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
        {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@400;700&display=swap'
        },
        ...navLinks(),
        ...footerLinks(),
        ...catchBoundaryCardLinks()
    ];
};

export const meta: MetaFunction = () => ({
    charset: 'utf-8',
    title: 'OSC Request Form',
    viewport: 'width=device-width,initial-scale=1'
});

export const headers: HeadersFunction = () => ({
    'Accept-CH': 'Sec-CH-Prefers-Color-Scheme'
});

export const loader: LoaderFunction = async ({ request }) => ({
    colorScheme: await getColorScheme(request)
});

// all comments with commented out -- are related to the guideline implementation of chakra with remix
// code has been changed so css applies before chakra styles

const Document = withEmotionCache(({ children }: DocumentProps, emotionCache: EmotionCache) => {
    const serverStyleData = useEmotionCache(emotionCache);
    return (
        <html lang="en">
            <head>
                <Links />
                <Meta />
                {serverStyleData.map(({ key, ids, css }) => (
                    <style
                        key={key}
                        data-emotion={`${key} ${ids.join(' ')}`}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(css) }}
                    />
                ))}
            </head>
            <body>
                {children}
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
            </body>
        </html>
    );
});

export default function App() {
    const { colorScheme } = useLoaderData();
    return (
        <Document>
            <ChakraProvider theme={colorScheme === 'light' ? lightTheme : darkTheme}>
                <Outlet />
            </ChakraProvider>
        </Document>
    );
}

export const ErrorBoundary = withEmotionCache(
    ({ error }: { error: { [x: string]: string } }, emotionCache: EmotionCache) => {
        const serverStyleData = useEmotionCache(emotionCache);
        const submit = useSubmit();
        const avatarUrl = undefined;

        const onClick = (e: MouseEvent<HTMLButtonElement>) => {
            submit(e.currentTarget);
        };

        return (
            <html>
                <head>
                    <title>Oh no!</title>
                    <Meta />
                    <Links />
                    {serverStyleData.map(({ key, ids, css }) => (
                        <style
                            key={key}
                            data-emotion={`${key} ${ids.join(' ')}`}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(css) }}
                        />
                    ))}
                </head>
                <body className="m-4">
                    <ChakraProvider theme={darkTheme}>
                        <div className="o-row">
                            <div className="o-row__container">
                                <NavBar
                                    hideLogoutButton={true}
                                    logoChildren={logoChildren}
                                    navChildren={NavChildren}
                                    avatarUrl={
                                        typeof avatarUrl === 'string' ? avatarUrl : undefined
                                    }
                                    prefetch={false}
                                >
                                    {/* logout menuitem, use submit can't be used inside a story */}
                                    <Button menuButton={true} onClick={onClick}></Button>
                                </NavBar>
                                <main style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <ErrorBoundaryCard />
                                </main>
                            </div>
                            <Footer />
                        </div>
                    </ChakraProvider>
                </body>
            </html>
        );
    }
);

export const CatchBoundary = withEmotionCache((emotionCache: EmotionCache) => {
    const submit = useSubmit();
    const caught = useCatch();
    const serverStyleData = useEmotionCache(emotionCache);
    const avatarUrl = undefined;
    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        submit(e.currentTarget);
    };

    return (
        <html>
            <head>
                <title>Oh no!</title>
                <Meta />
                <Links />
                {serverStyleData.map(({ key, ids, css }) => (
                    <style
                        key={key}
                        data-emotion={`${key} ${ids.join(' ')}`}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(css) }}
                    />
                ))}
            </head>
            <body className="m-4">
                <ChakraProvider theme={darkTheme}>
                    <div className="o-row">
                        <div className="o-row__container">
                            <NavBar
                                hideLogoutButton={true}
                                logoChildren={logoChildren}
                                navChildren={NavChildren}
                                avatarUrl={typeof avatarUrl === 'string' ? avatarUrl : undefined}
                                prefetch={false}
                            >
                                {/* logout menuitem, use submit can't be used inside a story */}
                                <Button menuButton={true} onClick={onClick}></Button>
                            </NavBar>
                            <main style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <CatchBoundaryCard caught={caught} />
                            </main>
                        </div>
                        <Footer />
                    </div>
                </ChakraProvider>
            </body>
        </html>
    );
});
