import type { FC } from 'react';
import type { LinksFunction } from 'remix';
import { Link } from 'remix';
import Card from './Card';
import { Flex, Heading, Button as ChakraButton, Text } from '@chakra-ui/react';

export interface Props {
    [x: string]: { [x: string]: string };
}

export const links: LinksFunction = () => {
    return [];
};

const ErrorBoundaryCard: FC<Props> = (props) => {
    return (
        <Card justifyContent={'space-evenly'}>
            <Heading p={5} color="secondary">
                Something went wrong!
            </Heading>
            <Text p={5} color="secondary">
                If you continue to have troubles, please contact us.
            </Text>
            <Flex p={5} justifyContent={'center'}>
                <Link to="/requests">
                    <ChakraButton display="flex">Go Back</ChakraButton>
                </Link>
            </Flex>
        </Card>
    );
};

export default ErrorBoundaryCard;
